@use 'spacing';
@use 'breakpoints';

.wrapper {
	flex: 1;
	width: 100%;
	padding-bottom: spacing.$s400;
	background-color: var(--background-default-first);

	@media only screen and (min-width: breakpoints.$lg) {
		padding-bottom: spacing.$s0;
	}
}

.isLargeWrapper {
	flex: 2;
}

.header {
	display: flex;
	flex-direction: column;
}

.body {
	padding: spacing.$s400 spacing.$s600;
}

.metaModules {
	position: relative;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	color: var(--text-default-second);
}

.title {
	margin-block: spacing.$s400;
}
