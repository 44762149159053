.wrapper {
	display: flex;
	flex: initial;
	flex-direction: column;

	width: 100%;
	padding-bottom: 0;
}

.header {
	display: flex;
	flex-direction: column;
}
