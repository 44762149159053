@use 'breakpoints';
@use 'spacing';
@import './variables';

$layer-1: 3;
$layer-2: $layer-1 + 1;
$button-offset: $play-button-dimension / 2;
$max-page-width: 96em;
$medium-sidebar-width: 260px;

.mediaImage,
.controlsWrapper {
	position: absolute;
	inset: 0;
}

.controlsWrapper {
	pointer-events: none;

	z-index: $layer-1;

	container-type: inline-size;
	display: flex;
	align-items: flex-end;

	padding: spacing.$s400;

	color: var(--text-consistent-od-01);

	& > * {
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}
	}

	&.isHero {
		@media only screen and (min-width: breakpoints.$lg) {
			// 28% of the total width minus the sidebar width
			margin-right: calc(((100% + $medium-sidebar-width) * 0.28) - $medium-sidebar-width);

			// 12% of the total width which is 100% plus the sidebar width
			margin-left: calc((100% + $medium-sidebar-width) * 0.12);
			padding-right: 0;
			padding-bottom: calc(80px + spacing.$s400);
			padding-left: 0;

			transition: padding-bottom 600ms ease-in-out;
		}

		@media only screen and (min-width: $max-page-width) {
			//  width times 30% minus width of sidebar
			margin-right: $max-page-width * 0.3 - 21em;

			// 10% of max page width, as the article content has the same 10% margin
			margin-left: $max-page-width * 0.1;
			padding-right: 0;
			padding-bottom: calc(155px + spacing.$s400);
			padding-left: 0;

			transition: padding-bottom 600ms ease-in-out;
		}
	}

	.playButton {
		@container (max-width: #{breakpoints.$xl / 3}) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
