@use 'breakpoints';

.image {
	margin: 0 auto;
}

.title {
	padding-bottom: 0;
	color: var(--text-default-first);
	text-align: center;

	@media only screen and (min-width: breakpoints.$lg) {
		padding-bottom: 0;
	}
}
