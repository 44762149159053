@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.header {
	display: flex;
	flex-direction: column;
	margin-bottom: spacing.$s400;
}

.withVerticalPadding {
	padding-top: spacing.$s400;
}

.meta {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	height: calc(br24-common.pixel-to-rem(30px) + 1rem);
	min-height: br24-common.pixel-to-rem(33px);
	padding-top: spacing.$s400;
	padding-right: 0;
	padding-left: 0;

	color: var(--text-default-second);
}

.wrapper {
	position: relative;
	flex: none;
	width: 100%;
	padding-bottom: spacing.$s400;

	@media only screen and (min-width: breakpoints.$lg) {
		padding-bottom: 0;
	}
}

.title {
	margin-block: spacing.$s400;
}
