@use 'spacing';
@use 'breakpoints';

.videoSection {
	display: flex;
	flex: 1;
	flex-direction: column;

	margin-bottom: spacing.$s400;

	color: var(--text-consistent-od-01);

	background-color: var(--background-default-third);

	@media only screen and (min-width: breakpoints.$lg) {
		margin-bottom: 0;
	}
}

.videoSectionTitle {
	display: flex;
	justify-content: center;
	padding: spacing.$s400;
}

.bannerSection {
	z-index: 4;

	display: flex;
	flex: 1;
	gap: spacing.$s600;
	align-items: center;
	justify-content: center;

	padding: spacing.$s300 spacing.$s1000;
}

.bannerMediathek {
	text-align: center;
}

.externalLink {
	flex-shrink: 0;
}
