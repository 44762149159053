@use 'spacing';
@use 'breakpoints';

.wrapper {
	position: relative;
	flex: 1;
	width: 100%;
	padding-bottom: spacing.$s400;

	&:last-child {
		padding-bottom: spacing.$s0;
	}

	@media only screen and (min-width: breakpoints.$lg) {
		padding-bottom: spacing.$s0;
	}
}

.large {
	flex: 2;
}

.iframe {
	display: block;
	width: 100%;
	opacity: 0;

	&.initialized {
		opacity: 1;
	}
}
