@import './Shared/variables';

.overlay {
	transition: $overlay-transition;

	&.video {
		.stopped & {
			opacity: 1;
		}

		.paused &,
		.playing & {
			z-index: -1;
			opacity: 0;
		}
	}
}

.player {
	position: absolute;
	z-index: 3;
	top: 0;

	width: 100%;

	opacity: 1;

	transition: $overlay-transition;

	.video & {
		height: 100%;
	}

	& .stopped & {
		opacity: 0;
	}

	& .paused &,
	& .playing & {
		opacity: 1;
	}
}

.titleRoot {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
}
