@use 'spacing';
@use 'breakpoints';

.rightRoot {
	z-index: 0;

	display: flex;
	flex: 1;
	flex-direction: column;

	width: 100%;

	background-color: var(--background-default-third);
}

.newsTeaserHeadline {
	display: none;

	min-height: 3.516rem;
	padding: spacing.$s500;

	color: var(--text-default-first);

	background-color: var(--background-default-first);

	&.mobiletitle {
		display: none;
	}

	h3,
	a {
		color: var(--text-default-first);
	}

	@media only screen and (min-width: breakpoints.$md) {
		padding: spacing.$s400;
	}

	@media only screen and (min-width: breakpoints.$lg) {
		display: block;

		&.mobiletitle {
			display: block;
		}
	}

	@media only screen and (min-width: breakpoints.$xl) {
		min-height: 3.6rem;
		padding: spacing.$s500 spacing.$s700;
	}
}

.showMobileTitle {
	display: block;

	@media only screen and (min-width: breakpoints.$lg) {
		display: none;
	}
}

.hero {
	margin-bottom: spacing.$s400;

	@media only screen and (min-width: breakpoints.$lg) {
		margin-bottom: 0;
	}
}

.radioWidget {
	flex-grow: 1;
}
