@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.wrapper {
	flex: 1;
	width: 100%;
	padding-bottom: spacing.$s400;
	color: var(--text-consistent-od-01);

	@media only screen and (min-width: breakpoints.$lg) {
		padding-bottom: 0;
	}
}

.header {
	display: flex;
	flex-direction: column;
}

// increase specificity to overwrite default background
.body.body {
	padding-top: spacing.$s400;
	padding-right: spacing.$s800;
	padding-left: spacing.$s800;
	background-color: var(--background-button-second);
}

.title {
	margin-bottom: br24-common.pixel-to-rem(80px);
	color: var(--text-consistent-od-01);
}
