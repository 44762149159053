@use 'spacing';
@use 'breakpoints';

.body {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding-right: spacing.$s800;
	padding-left: spacing.$s800;

	article {
		position: relative;
		flex: 0 0 auto;
		width: 100%;

		/* Divider Line */
		&::after {
			content: '';

			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;
			height: 1px;

			background-color: var(--text-default-second);
		}
	}
}

.header {
	display: flex;
	flex-direction: column;

	padding-top: spacing.$s400;
	padding-right: spacing.$s800;
	padding-left: spacing.$s800;
}

.footer {
	display: flex;
	flex-direction: row;

	padding-top: spacing.$s400;
	padding-right: spacing.$s800;
	padding-left: spacing.$s800;

	ul {
		display: flex;
		flex-direction: row;

		margin: 0;
		padding: 0;

		list-style-type: none;
	}
}

.list {
	justify-content: space-between;
	width: 100%;
	padding-bottom: spacing.$s400;
}

.wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;

	padding-top: spacing.$s400;
	padding-bottom: spacing.$s400;

	background-color: var(--background-default-first);

	@media only screen and (min-width: breakpoints.$lg) {
		border: 0;
	}
}
