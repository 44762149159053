@use 'spacing';
@use 'breakpoints';

.styledChevron {
	margin-right: spacing.$s200;
}

.title {
	position: relative;
	display: inline-flex;
	align-items: center;
	margin: spacing.$s400;
}

.wrapper {
	width: 100%;
	border-bottom: 5px solid var(--background-button-first);
	color: var(--text-default-first);
	background-color: var(--background-default-first);
}
