@use 'breakpoints';

.row {
	display: flex;
	flex-direction: column;
	width: 100%;

	@media only screen and (min-width: breakpoints.$lg) {
		flex-direction: row;
	}
}
