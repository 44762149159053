@use 'br24-common';

$button-dimension-m: br24-common.pixel-to-rem(2 * 10px + 24px);
$button-dimension-s: br24-common.pixel-to-rem(2 * 5px + 24px);

@mixin component-style($className) {
	#{$className} {
		display: flex;
		place-items: center center;
		justify-content: center;

		border: none;
		border-radius: 50%;

		color: var(--icon-consistent-ol);

		&:focus-visible {
			outline: var(--outline-width) double var(--link-highlight);
			outline-offset: var(--outline-offset);
		}

		&,
		&:hover {
			color: var(--icon-consistent-ol);
		}

		&.primary,
		&.tertiary {
			background-color: var(--background-icon-first);
		}

		&.secondary {
			background-color: var(--background-icon-second);
		}

		&.secondary,
		&.tertiary {
			&:focus-visible {
				background-color: var(--background-highlight-first);
			}

			// this applies the hover effect only on hover enabled devices
			// thus mobile phones are excluded and will not show the hover
			// state when the button was clicked https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
			@media screen and (hover: hover) and (pointer: fine) {
				&:hover {
					background-color: var(--background-highlight-first);
				}
			}
		}

		&:active {
			box-shadow: br24-common.pixel-to-rem(2px) br24-common.pixel-to-rem(2px) br24-common.pixel-to-rem(11px) 0
				var(--background-button-second);
		}

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			cursor: pointer;

			& .icon {
				transform: scale(1.1);
			}
		}
	}

	.m {
		width: $button-dimension-m;
		height: $button-dimension-m;
	}

	.s {
		width: $button-dimension-s;
		height: $button-dimension-s;
	}

	.icon {
		transition-timing-function: ease-in-out;
		transition-duration: 200ms;
		transition-property: color, transform;
	}
}
