@import '../Shared/variables';

.overlay {
	// this will show the player in the correct position while playing
	height: 0;
	transition: $overlay-transition;

	.stopped & {
		opacity: 1;
	}

	.paused &,
	.playing & {
		z-index: -1;
		opacity: 0;
	}
}

.player {
	transition: $overlay-transition;

	.stopped & {
		opacity: 0;
	}

	.paused &,
	.playing & {
		opacity: 1;
	}
}

.videoTitleRoot {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: space-between;

	height: $play-button-dimension;

	color: var(--text-consistent-od-01);
}
