@use 'spacing';

.section {
	overflow: hidden;

	&:not(:first-of-type) {
		margin-top: spacing.$s400;
	}
}

// increase specificity to allow overwriting of color and background
.isHighlightWrapper.isHighlightWrapper {
	color: var(--text-consistent-od-01);
	background: var(--background-button-second);
}
