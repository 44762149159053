@use 'br24-common';
@use 'spacing';

.richText {
	& > * {
		margin-bottom: spacing.$s800;
		color: var(--text-default-first);

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: underline;
	}

	blockquote {
		position: relative;
		margin-left: br24-common.pixel-to-rem(24px);
		padding-left: spacing.$s200;

		&::before {
			content: url('./icons/quote-open-light.svg');

			position: absolute;
			top: br24-common.pixel-to-rem(3px);
			left: br24-common.pixel-to-rem(-18px);

			display: block;

			width: br24-common.pixel-to-rem(24px);
			height: br24-common.pixel-to-rem(24px);

			color: var(--background-highlight-second);

			[data-theme='dark'] & {
				content: url('./icons/quote-open-dark.svg');
			}
		}

		&::after {
			content: url('./icons/quote-close-light.svg');

			display: inline-block;

			width: br24-common.pixel-to-rem(24px);
			height: br24-common.pixel-to-rem(24px);
			margin-top: br24-common.pixel-to-rem(-2px);

			color: var(--background-highlight-second);
			vertical-align: middle;

			[data-theme='dark'] & {
				content: url('./icons/quote-close-dark.svg');
			}
		}
	}

	h2,
	h3 {
		margin-bottom: spacing.$s300;
	}

	ol {
		list-style: none;

		li {
			counter-increment: item;
			position: relative;
			margin-bottom: spacing.$s200;
			padding-left: spacing.$s500;

			&::before {
				content: counter(item) '.';

				position: absolute;
				top: 0;
				left: 0;

				display: block;
			}
		}
	}

	ul {
		list-style: none;

		li {
			position: relative;
			margin-bottom: spacing.$s200;
			padding-left: spacing.$s500;

			&::before {
				content: '•';

				position: absolute;
				top: 0;
				left: 0;

				display: inline-block;

				width: br24-common.pixel-to-rem(6px);
				height: br24-common.pixel-to-rem(6px);
				margin-right: spacing.$s400;

				font-size: 1.4rem;
			}
		}
	}
}
