@use 'spacing';
@use 'breakpoints';

.wrapper {
	flex: 1;
	width: 100%;
	padding-block: spacing.$s400;
	background-color: var(--background-default-first);

	@media only screen and (min-width: breakpoints.$lg) {
		padding-bottom: spacing.$s0;
	}
}

.innerWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	padding-inline: spacing.$s800;
}

.item {
	/* Divider Line */
	&::after {
		content: '';

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 1px;

		background-color: var(--text-default-second);
	}

	&:last-child::after {
		content: none;
	}
}
