@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.newsTeaserDiv {
	z-index: 0;

	display: flex;
	flex-direction: column;
	gap: spacing.$s50;

	height: auto;
	margin-top: spacing.$s50;

	background: var(--background-default-third);
}

.newsTeaserHeadline {
	display: none;
	margin: spacing.$s600;
	color: var(--text-consistent-od-01);

	& > a {
		display: block;
	}
}

.showMobileTitle {
	display: none;

	@media only screen and (min-width: breakpoints.$lg) {
		display: block;
	}
}

.showDesktopTitle {
	display: block;
}

.newsTeaserItem {
	overflow: hidden;
	display: flex;
	flex: 1 1 auto;

	color: var(--text-default-first);

	background-color: var(--background-default-first);

	// show the outline on the h3 title element instead of on the card link
	outline: none;

	&:last-of-type {
		border-bottom: none;
	}

	&:hover {
		h3 {
			color: var(--text-highlight-second);
			text-decoration: underline;
		}
	}

	&:focus-visible {
		h3 {
			color: var(--text-highlight-second);
			outline: var(--outline-width) double var(--link-highlight);
			outline-offset: var(--outline-offset);
		}
	}
}

.newsTeaserItemImage {
	display: flex;
	flex: 1 1 br24-common.pixel-to-rem(188px);
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	justify-content: center;

	max-width: br24-common.pixel-to-rem(350px);
}

.newsTeaserItemText {
	flex: 1 1 br24-common.pixel-to-rem(188px);
	align-self: center;
	margin: spacing.$s300 spacing.$s600;
}
