@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.wrapper {
	overflow-x: hidden;
	flex: 1;
	width: 100%;
	background-color: var(--background-default-first);

	&:not(:last-child) {
		border-bottom: 1rem solid var(--background-color);

		@media only screen and (min-width: breakpoints.$lg) {
			border-bottom: none;
		}
	}
}

.isLargeWrapper {
	flex: 2;
}

.content {
	padding: spacing.$s400 spacing.$s600;
}

.header {
	display: flex;
	flex-direction: column;
}

.title {
	margin-top: spacing.$s400;
}

.teaser {
	margin-top: spacing.$s500;
}

.authorsComments {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	color: var(--text-default-second);
}

.dot {
	margin-inline: spacing.$s200;
}

.metaModules {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	color: var(--text-default-second);
}

.relatedLink {
	overflow: hidden;
	display: inline-block;

	width: 100%;

	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: middle;
}

.commentsLink {
	display: inline;
	flex-shrink: 0;
	word-break: keep-all;
	white-space: nowrap;
}

.isHero {
	margin-top: 0;
}

.line {
	width: br24-common.pixel-to-rem(48px);
	height: br24-common.pixel-to-rem(2px);
	margin: spacing.$s600 spacing.$s0;
	border: 0;

	background-color: var(--text-default-first);
}

.footer {
	margin-top: spacing.$s500;
}
